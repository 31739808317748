import React from "react";
import { Typography } from "@mui/material";
import theme from "../../assets/theme/theme";

const Copyrights = () => {
  return (
    <Typography
      textAlign="center"
      component="p"
      fontSize={12}
      color={theme.palette.text.secondary}
      sx={{ marginInline: "18px" }}
      mt="auto"
    >
      Copyright &copy; 2024 DocuComb Inc. <br />
      All rights reserved.
    </Typography>
  );
};

export default Copyrights;
