import { Box, Tooltip, IconButton, Typography, Stack } from "@mui/material";
import React, { useEffect, useCallback, useRef } from "react";
import { pdfjs } from "react-pdf";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { useAuth } from "../../contexts/AuthContext";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import ImageViewer from "./ImageViewer";
import { PinturaEditor } from "@pqina/react-pintura";
import { blobToFile, getEditorDefaults } from "@pqina/pintura";

// image (download, print) + pdf (download, print, pages)
export interface DocumentViewerProps {
  url: string;
  file_format: string;
  isSignedURL?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  isViewOnly?: boolean;
  styles?: any;
  isDownloadingDoc?: boolean;
  onDownloadClick?: () => any;
  isPrintProcessing?: boolean;
  onPrintClick?: () => any;
  reInitialize?: boolean;
  onLoad?: () => void;
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentViewer: React.FC<DocumentViewerProps> = ({
  url,
  file_format,
  isViewOnly,
  isSignedURL,
  onLoad,
  onDownloadClick,
  isPrintProcessing,
  isDownloadingDoc,
  onPrintClick,
  reInitialize = false,
}) => {
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const { currentUser } = useAuth();
  const previewWrapperRef = useRef<HTMLDivElement>(null);

  // As per recommended here, https://github.com/wojtekmaj/react-pdf/wiki/Frequently-Asked-Questions#react-pdf-reloads-itself-with-every-render-whats-going-on
  const [pdf, setPdf] = React.useState<File | undefined>();
  const [image, setImage] = React.useState<string>("");
  const [hasPDFFetched, setHasPDFFetched] = React.useState(false);
  const isDocFetched = hasPDFFetched || !!image;

  useEffect(() => {
    // reset loading state on url change
    setNumPages(0);
    setPageNumber(1);
    setHasPDFFetched(false);
  }, [url]);

  useEffect(() => {
    async function init() {
      if (isSignedURL) {
        const headers = new Headers();
        const response = await fetch(url, { headers });
        const blob = await response.blob();
        if (
          file_format === "image/png" ||
          file_format === "image/jpeg" ||
          file_format === "image/jpg"
        ) {
          const objectUrl = URL.createObjectURL(blob);
          setImage(objectUrl);
        } else {
          try {
            const file = blobToFile(blob, "");
            setPdf(file);
          } catch (err) {
            console.error(err);
          }
          setHasPDFFetched(true);
        }
      } else {
        if (currentUser) {
          const token = await currentUser.getIdToken();
          const headers = new Headers();
          headers.set("Authorization", `Bearer ${token}`);
          const response = await fetch(url, { headers });
          const blob = await response.blob();
          if (
            file_format === "image/png" ||
            file_format === "image/jpeg" ||
            file_format === "image/jpg"
          ) {
            const objectUrl = URL.createObjectURL(blob);
            setImage(objectUrl);
          } else {
            try {
              const file = blobToFile(blob, "");
              setPdf(file);
            } catch (err) {
              console.error(err);
            }
            setHasPDFFetched(true);
          }
        }
      }
    }
    init();
  }, [url, currentUser, file_format, isSignedURL]);

  useEffect(() => {
    document.documentElement.style.setProperty("--editor-height", "100%");
  }, [pdf]);

  const editorPdfconfig = getEditorDefaults({
    imageReader: {
      preprocessImageFile: async (
        file: File,
        options: any,
        onprogress: any
      ) => {
        // If is not a pdf we return the original file
        if (!/pdf$/.test(file.type)) return file;

        // let's convert the pdf to a png
        const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;

        const numOfpages = pdf.numPages;
        setNumPages(numOfpages);

        // get first page
        const page = await pdf.getPage(pageNumber);

        // get a scaled viewport for the pdf
        const viewport = page.getViewport({ scale: 5 });

        // create the target canvas to draw on
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // ask pdfjs to draw to the canvas
        await page.render({
          canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
          transform: [],
          viewport: viewport,
        }).promise;

        // we turn the canvas into a blob
        const blob: any = await new Promise((resolve) =>
          canvas.toBlob(resolve)
        );

        try {
          // Pintura Image Editor expects a File
          return blobToFile(blob, file.name);
        } catch (err) {
          console.error(err);
          return null;
        }
      },
    },
  });

  // to update the rendered pdf page in pintura editior, after updating the page number you have to call updateImage
  const updateStatePintura = useCallback(() => {
    if (viewerRef.current && pdf) {
      try {
        viewerRef.current.editor.updateImage(pdf);
      } catch (err) {
        console.error(err);
      }
    }
  }, [pdf]);

  const changePage = useCallback(
    (offset: number) => {
      document.documentElement.style.setProperty("--editor-height", "100%");
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
      logEvent(analytics, "page_change", {
        component: "pDFViewer",
        page: pageNumber + offset,
        url,
      });
    },
    [pageNumber, url]
  );

  useEffect(() => {
    setTimeout(updateStatePintura, 1000);
  }, [pageNumber, updateStatePintura, url]);

  const previousPage = useCallback(() => {
    changePage(-1);
  }, [changePage]);

  const nextPage = useCallback(() => {
    changePage(1);
  }, [changePage]);

  // const getContentWithWatermark = (children: ReactNode) => {
  //   return isViewOnly ? <WaterMark>{children}</WaterMark> : children;
  // };

  const viewerRef = useRef<any>(null);

  return (
    <>
      {!isDocFetched ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" component="p">
            Fetching document...
          </Typography>
        </Box>
      ) : (
        <Stack
          height="100%"
          direction="column"
          spacing={4}
          sx={{
            alignItems: "center",
          }}
        >
          {(file_format === "application/pdf" ||
            file_format === "pdf" ||
            file_format === undefined) && (
            <Box
              className="page-numbers-preview"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
                maxHeight: "calc(100% - 72px)",
              }}
            >
              <Typography component="p">
                Pagess {pageNumber || "--"} of {numPages || "--"}
              </Typography>
              <Box>
                <Tooltip title="Previous page" arrow placement="top">
                  <IconButton
                    className="page-number-buttons"
                    aria-label="previous page"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Next page" arrow placement="top">
                  <IconButton
                    className="page-number-buttons"
                    aria-label="next page"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Tooltip>
                {!isViewOnly && (
                  <Tooltip title="Print" arrow placement="top">
                    <IconButton
                      className="page-number-buttons"
                      aria-label="print document"
                      onClick={onPrintClick}
                      disabled={isPrintProcessing || !isDocFetched}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!isViewOnly && (
                  <Tooltip title="Download" arrow placement="top">
                    <IconButton
                      className="page-number-buttons"
                      aria-label="download document"
                      disabled={isDownloadingDoc || !isDocFetched}
                      onClick={onDownloadClick}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              overflow: "hidden",
              margin: 0,
            }}
          >
            <Box
              id="PrintPDF"
              sx={{
                width: "100%",
              }}
            >
              {(file_format === "application/pdf" ||
                file_format === "pdf" ||
                file_format === undefined) && (
                <Box
                  style={{ height: "100%", width: "100%", overflow: "scroll" }}
                  ref={previewWrapperRef}
                >
                  <PinturaEditor
                    {...editorPdfconfig}
                    previewUpscale={true}
                    src={pdf}
                    ref={viewerRef}
                    enableUtils={false}
                    enableToolbar={false}
                    enableButtonExport={false}
                    cropEnableZoomInput={false}
                    cropEnableRotationInput={false}
                    cropEnableButtonFlipHorizontal={false}
                    cropEnableButtonRotateLeft={false}
                    cropEnableImageSelection={false}
                    onLoad={({ size: { width, height } }) => {
                      // Manually computing the preview document height considering the viewport dimension and document aspect ratio for maximum resolution
                      if (previewWrapperRef?.current?.clientWidth) {
                        document.documentElement.style.setProperty(
                          "--editor-height",
                          `${
                            (previewWrapperRef.current.clientWidth - 32) /
                            (width / height)
                          }px`
                        );
                      }
                      console.log(
                        "onLoadpreviews: ",
                        document.documentElement.style.getPropertyValue(
                          "--editor-height"
                        )
                      );
                    }}
                    // onLoad={event => {
                    //   if (reInitialize) {
                    //     setTimeout(() => viewerRef.current.editor.updateImage(event.src), 1000)
                    //     onLoad?.()
                    //     console.log("onLoad: ", event)
                    //   }
                    // }}
                  ></PinturaEditor>
                </Box>
              )}
              {(file_format === "image/png" ||
                file_format === "image/jpeg" ||
                file_format === "image/jpg") && (
                <>
                  <Box style={{ height: "600px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                      }}
                    >
                      {!isViewOnly && (
                        <Tooltip title="Print" arrow placement="top">
                          <IconButton
                            aria-label="print document"
                            onClick={onPrintClick}
                            disabled={isPrintProcessing || !isDocFetched}
                            color="primary"
                          >
                            <PrintIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!isViewOnly && (
                        <Tooltip title="Download" arrow placement="top">
                          <IconButton
                            aria-label="download document"
                            disabled={isDownloadingDoc || !isDocFetched}
                            onClick={onDownloadClick}
                            color="primary"
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <ImageViewer
                      image={image}
                      onLoadpreview={({ width, height }) => {
                        // Manually computing the preview document height considering the viewport dimension and document aspect ratio for maximum resolution
                        if (previewWrapperRef?.current?.clientWidth) {
                          document.documentElement.style.setProperty(
                            "--editor-height",
                            `${
                              previewWrapperRef.current.clientWidth /
                              (width / height)
                            }px`
                          );
                        }
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default DocumentViewer;
