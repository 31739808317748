import { map, reduce, filter } from "lodash";
import React, { useEffect } from "react";
import { INotificationsEnum } from "../firebaseTypes";
import { useDocumentContext } from "./DocumentContext";
import constants from "../constants";
import {
  getDisplayValue,
  getExpiryFieldValue,
  isPassport,
} from "../utils/documentUtils";
import { ref, update } from "firebase/database";
import { auth, rtdb } from "../firebase";
import { closeSnackbar } from "notistack";

export interface INotification {
  id: string;
  doc_id: string;
  type: INotificationsEnum;
  title: string; 
  doc_type: string;
  timeStamp: number;
  archived?: boolean;
  seen?: boolean;
  associatedDocs: string[];
  metadata?: {
    // doc-expiry notification
    formattedExpiryDateStr: string;
    helpLink: string;
    helpText: string;
  };
}

export interface INotificationsContext {
  notifications: INotification[];
  archiveNotification: (notification: INotification) => any;
  markAllNotificationsSeen: () => any;
}

const defaultValues: INotificationsContext = {
  notifications: [],
  archiveNotification: () => { },
  markAllNotificationsSeen: () => { },
};

const NotificationsContext = React.createContext(defaultValues);

export function useNotifications() {
  return React.useContext(NotificationsContext);
}

export const NotificationsProvider: React.FC = (props) => {
  const [notifications, setNotifications] = React.useState(
    defaultValues.notifications
  );
  const { documents } = useDocumentContext();

  const getNotificationMetadata = (doc: any) => {
    const expiryDateStr = getExpiryFieldValue(doc.doc_info);
    const expiryDisplayValue = getDisplayValue(expiryDateStr);


    let helpLink = "";
    let helpText = "";
    const     formattedExpiryDateStr = expiryDisplayValue
    ? `Expiring on ${expiryDisplayValue}`
    : "Expiry date not set";

    if (isPassport(doc)) {
      helpLink = constants.blogs.renewPassport;
      helpText = "How to re-new your Passport?";
    }
    // get the notifications of the doc and check id doc-relation exists
    if (doc.notifications) {
      const docRelation = doc.notifications.find(
        (note: { type: INotificationsEnum }) =>
          note.type === INotificationsEnum["doc-relation"]
      );
      if (docRelation) {
        helpLink = "/get-expert-help";
        helpText = "Get expert help";
      }
    }
    return { helpLink, helpText, formattedExpiryDateStr };
  }

  React.useEffect(() => {
    const notifications = reduce(
      documents,
      (result, doc) => {
        if (doc.notifications && doc.notifications.length > 0) {
          // filter out the archived notifications
          // doc.notifications = filter(
          //   doc.notifications,
          //   (note) => !note.archived
          // );
          const toAdd = map(
            doc.notifications as {
              id : string;
              type: INotificationsEnum;
              timeStamp: number;
              archived?: boolean;
              seen: boolean;
            }[],
            (note) => {
              const { helpLink, helpText, formattedExpiryDateStr} = getNotificationMetadata(doc);
              // if note.see does not exist, set it to false
              if (note.seen === undefined) {
                note.seen = true;
              }
              if (note.archived === undefined) {
                note.archived = true;
              }
              return {
                id: note.id,
                doc_id: doc.id,
                doc_type: doc.doc_type,
                associatedDocs: [doc.id],
                title: `${doc.doc_name}`,
                type: note.type,
                timeStamp: note.timeStamp,
                archived: note.archived,
                seen: note.seen,
                metadata: {
                  helpLink,
                  helpText,
                  formattedExpiryDateStr,
                },
              } as INotification;
            }
          );
          return [...result, ...toAdd];
        }
        return result;
      },
      [] as INotification[]
    );
    notifications.sort((a, b) => b.timeStamp - a.timeStamp);
    // remove notifications that don't have id field from the array of notifications
    let filteredNotifications = notifications.filter((note) => note.id !== undefined);
    // also remove doc-relation notifications from the array of notifications
    filteredNotifications = notifications.filter((note) => note.type !== INotificationsEnum["doc-relation"]);
    setNotifications(filteredNotifications);
  }, [documents]);

  console.log("notifications", notifications);
  

  const getNotificationUpdatedArrayArchived = (
    notifications: {
      id: string;
      doc_id: string;
      type: INotificationsEnum;
      timeStamp: number;
      archived: boolean;
      seen: boolean;
    }[],
    archiveNotification: INotification
  ) => {
    return notifications.map((note) => {
      if (note.id === archiveNotification.id) {
        return { 
          id: note.id,
          type: note.type,
          timeStamp: note.timeStamp,
          archived: true,
          seen: true,
        };
      }
      return {
        id: note.id,
        type: note.type,
        timeStamp: note.timeStamp,
        archived: note.archived,
        seen: note.seen,
      };
    });
  };

  const archiveNotification = React.useCallback(
    async (notification: INotification) => {
      // update the local state
      const newNotifications = filter(
        notifications,
        (note) => note.id !== notification.id
      );
      setNotifications(newNotifications);
      // get the document
      const doc = documents.find((doc) => doc.id === notification.doc_id);
      if (!doc) {
        return;
      }
      // update the rtdb
      await update(
        ref(rtdb, `users/${auth.currentUser?.uid}/documents/${doc.id}`),
        {
          notifications: getNotificationUpdatedArrayArchived(
            doc.notifications as {
              id: string;
              doc_id: string;
              type: INotificationsEnum;
              timeStamp: number;
              archived: boolean;
              seen: boolean;
            }[], notification
          ),
        }
      );
    },
    [documents, notifications]
  );

  const getNotificationUpdatedArraySeen = (
    notifications: {
      id: string
      type: INotificationsEnum;
      timeStamp: number;
      archived: boolean;
      seen: boolean;
    }[]
  ) => {
    return notifications.map((note) => {
      if (!note.seen) {
        return {
          ...note,
          seen: true,
        };
      } else {
        return note;
      }
    });
  };

  // mark all the notifications seen field as true
  const markAllNotificationsSeen = async () =>{
    try {
      // check if any notification has seen !== true
      
      const hasUnseen = notifications.find((note) => note.seen !== true);
      if (!hasUnseen) {
        return;
      }
      const newNotifications = notifications.map((note) => {
        return {...note, seen: true };
      });
      setNotifications(newNotifications);
      // map through all notifications and update the seen field to true
      Promise.all(
        notifications.map(async (note) => {
          const doc = documents.find((doc) => doc.id === note.doc_id);
          if (!doc) {
            return;
          }
          console.log("doc noti", doc.notifications);
          
          await update(
            ref(rtdb, `users/${auth.currentUser?.uid}/documents/${doc.id}`),
            {
              notifications: getNotificationUpdatedArraySeen(
                doc.notifications as {
                  id: string;
                  type: INotificationsEnum;
                  timeStamp: number;
                  archived: boolean;
                  seen: boolean;
                }[]
              ),
            }
          );
        })
      );
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    return closeSnackbar;
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        archiveNotification,
        markAllNotificationsSeen,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};
