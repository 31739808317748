import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";
import { useDocumentContext } from "../../contexts/DocumentContext";
import DialogCloseIconBtn from "../DialogCloseIconBtn";
import theme from "../../assets/theme/theme";
import { useAuth } from "../../contexts/AuthContext";
// import PrintIcon from "@mui/icons-material/Print";
// import LoadingButton from "@mui/lab/LoadingButton";
import DocumentViewer from "../Shared/DocumentViewer";
import { map } from "lodash";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import { getDocName } from "../../utils/documentUtils";
import { IDocument } from "../../firebaseTypes";


export interface Props { }

const DocumentModal: React.FC<Props> = () => {
  const isDownloadingRef = React.useRef(false);
  const { open, handleClose, document } =
    usePdfPreviewModalContext();
  const { getDocDownloadUrl, downloadDocument } = useDocumentContext();
  const { currentUser } = useAuth();
  const { print, isPrintProcessing } = usePDFMergeContext();
  const [isDownloadingDoc, setIsDownloadingDoc] = useState(false);
  // const [skeletonNode] = React.useState(<Skeleton variant="rectangular" width={'900px'} height={'500px'} />)
  // const [file, setFile] = React.useState({
  //   url: "",
  // });

  // const [image, setImage] = React.useState<string>("");
  // const [total, setTotal] = React.useState(0);
  const file_format = document?.doc_format ? document?.doc_format : "";

  const [downloadableUrl, setDownloadableUrl] = React.useState<string>('');

  const onDownloadClick = useCallback(async () => {
    setIsDownloadingDoc(true);
    document && (await downloadDocument(document));
    setIsDownloadingDoc(false);
  }, [document, downloadDocument]);

  const onPrintClick = useCallback(async () => {
    if (document) {
      const docs = [document];
      const selectedPdf = docs.filter(
        (img) => img.doc_format === "application/pdf"
      );
      const selectedImg = docs.filter(
        (img) =>
          img.doc_format === "image/jpeg" ||
          img.doc_format === "image/png" ||
          img.doc_format === "image/jpg"
      );

      const downloadURLs = map(selectedPdf, (d) => getDocDownloadUrl(d));

      const downloadImgURLs = map(selectedImg, (d) => getDocDownloadUrl(d));

      await print(downloadURLs, downloadImgURLs);
    }
  }, [document, getDocDownloadUrl, print]);

  // const getFile = useCallback(
  //   async (token: string, url: string, file_format?: string) => {
  //     const httpHeaders = {
  //       Authorization: `Bearer ${token}`,
  //     };
  //     setFile((prev) => ({
  //       ...prev,
  //       url,
  //       httpHeaders,
  //     }));
  //     if (
  //       file_format === "image/png" ||
  //       file_format === "image/jpeg" ||
  //       file_format === "image/jpg"
  //     ) {
  //       const headers = new Headers();
  //       headers.set("Authorization", `Bearer ${token}`);
  //       const response = await fetch(url, { headers });
  //       const blob = await response.blob();
  //       const objectUrl = URL.createObjectURL(blob);
  //       setImage(objectUrl);
  //       setTotal(1);
  //     }
  //   },
  //   []
  // );

  const init = React.useCallback(async () => {
    if (document && !isDownloadingRef.current && currentUser) {
      const url = getDocDownloadUrl(document);
      setDownloadableUrl(url)
    }
  }, [document, currentUser, getDocDownloadUrl]);

  // const initTravel = useCallback(async () => {
  //   if (travelDocument && !isDownloadingRef.current) {
  //     const { path, doc_format } = travelDocument;
  //     const url = await getDownloadURL(ref(storage, path));
  //     setDownloadableUrl(url)
  //     if (
  //       doc_format === "image/png" ||
  //       doc_format === "image/jpeg" ||
  //       doc_format === "image/jpg"
  //     ) {
  //       setImage(url);
  //       setTotal(1);
  //     }
  //   }
  // }, [travelDocument]);

  React.useEffect(() => {
    init();
  }, [init]);


  return (
    <>
      <Dialog 
        // fullScreen
        maxWidth="lg"
        className="dialog-box"
        // disableEscapeKeyDown
        open={open}
        onClose={() => {
          window.document.documentElement.style.setProperty('--editor-height', "100%");
          handleClose()
        }}
        aria-labelledby="preview document popup"
        aria-describedby="preview document popup"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxHeight: "100%",
              width:"100%",
              margin:"10px"
            },
          },
          ".PinturaUtilMain, .PinturaUtilPanel, .PinturaUtilFooter": {
            padding: 0
          },
          ".PinturaRootWrapper": {
            display: "flex",
            alignItems: "flex-start",
            padding: "0 10px"
          },
        }}
      >
        <DialogTitle className="preview-document">
          Preview Document{" "}
          <Typography
            sx={{
              font: "inherit",
              fontWeight: 600,
              color: theme.palette.primary.main,
            }}
            component="span"
          >
            {getDocName(document as IDocument)}
          </Typography>
          <DialogCloseIconBtn onClick={handleClose} />
        </DialogTitle>
        <DialogContent
          sx={{
            // select width according to media query
            width: "70%",
            maxHeight: "80vh",
            position: "relative",
            margin: "0 auto",
          }}
        >
          <Box sx={{ width: "100%", height: "600px"}}>
            <DocumentViewer
              url={downloadableUrl}
              file_format={file_format}
              isDownloadingDoc={isDownloadingDoc}
              isPrintProcessing={isPrintProcessing}
              onDownloadClick={onDownloadClick}
              onPrintClick={onPrintClick}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <LoadingButton
            disabled={total ? false : true}
            loading={isPrintProcessing}
            variant="outlined"
            loadingPosition="start"
            aria-label="print document"
            onClick={onPrintDocument}
            startIcon={<PrintIcon />}
          >
            Print
          </LoadingButton> */}
          <Button
            variant="outlined"
            aria-label="close window"
            onClick={() => {
              window.document.documentElement.style.setProperty('--editor-height', "100%");
              handleClose()
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentModal;
