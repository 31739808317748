import {
  Alert,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import React, { useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Illustration from '../../assets/images/illustrations/auth_illustration.svg';
import theme, { DcInput, FormWrapper } from '../../assets/theme/theme';
import { useAuth } from '../../contexts/AuthContext';
import CheckboxCheckedIcon from '../Shared/Icons/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '../Shared/Icons/CheckboxUncheckedIcon';

import Logo from '../Shared/Logo';

export interface Props { }

const SignUp: React.FC<Props> = (props) => {
  const [emailInput, setEmailInput] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [passwordInput, setPasswordInput] = useState('')

  const { signup, currentUser, signUpWithGoogle } = useAuth()
  const history = useHistory()

  const [error, setError] = useState('')
  const [agreeTC, setAgreeTC] = useState(true)

  const [loading, setLoading] = useState(false);

  function checkSignUpDisabled() {
    return loading || !emailInput || !passwordInput || !nameInput || !agreeTC;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await signup(emailInput, passwordInput, nameInput);
      history.push('/email-verify');
    } catch (error: any) {
      setError(`${error.code}: Failed to create an account.`);
    }

    setLoading(false);
  }

  if (currentUser) {
    return <Redirect to='/' />;
  }

  const TnCLabel = (
    <Typography variant='body2' mt={1}>
      By continuing, you agree to <br /> DocuComb’s{' '}
      <a
        target="_blank"
        href="https://immplify.com/terms-of-use" rel="noreferrer">
        <Typography
          color={theme.palette.text.primary}
          variant='body2'
          component='u'
        >
          Terms of Service
        </Typography>
      </a>{' '}
      and{' '}
      <a target="_blank"
        href="https://immplify.com/privacy-policy" rel="noreferrer">
        <Typography
          color={theme.palette.text.primary}
          variant='body2'
          component='u'
        >
          Privacy Policy
        </Typography>
      </a>
    </Typography>
  );

  return (
    <Container maxWidth={false} disableGutters className='full-height'>
      <Grid container spacing={0} className='full-height'>
        <Hidden mdDown>
          <Grid item xs={12} sm={7}>
            <Box
              display='flex'
              flex={1}
              flexDirection='column'
              className='full-height'
            >
              <Box mt={6} ml={6.5}>
                <Logo />
              </Box>
              <Box width='80%' m='auto'>
                <img src={Illustration} alt='Happy' width='100%' />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={5} flex={1}>
          <FormWrapper>
            <Box width='70%' mx='auto'>
              <Hidden mdUp>
                <Box p={8} textAlign='center'>
                  <Logo height={50} />
                </Box>
              </Hidden>
              <Grid container spacing={0} justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h3' component='h1' fontWeight={500}>
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item textAlign='right'>
                  Already have an account?
                  <Link to='/login' className='text-decoration-none'>
                    <Typography component='span' color='#4B84FF' ml={1}>
                      Sign In
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Box my={4}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                  startIcon={
                    <svg
                      id='Layer_1'
                      version='1.1'
                      viewBox='0 0 56.6934 56.6934'
                      height={24}
                      width={24}
                      fill='#FFF'
                    >
                      <path d='M51.981,24.4812c-7.7173-0.0038-15.4346-0.0019-23.1518-0.001c0.001,3.2009-0.0038,6.4018,0.0019,9.6017  c4.4693-0.001,8.9386-0.0019,13.407,0c-0.5179,3.0673-2.3408,5.8723-4.9258,7.5991c-1.625,1.0926-3.492,1.8018-5.4168,2.139  c-1.9372,0.3306-3.9389,0.3729-5.8713-0.0183c-1.9651-0.3921-3.8409-1.2108-5.4773-2.3649  c-2.6166-1.8383-4.6135-4.5279-5.6388-7.5549c-1.0484-3.0788-1.0561-6.5046,0.0048-9.5805  c0.7361-2.1679,1.9613-4.1705,3.5708-5.8002c1.9853-2.0324,4.5664-3.4853,7.3473-4.0811c2.3812-0.5083,4.8921-0.4113,7.2234,0.294  c1.9815,0.6016,3.8082,1.6874,5.3044,3.1163c1.5125-1.5039,3.0173-3.0164,4.527-4.5231c0.7918-0.811,1.624-1.5865,2.3908-2.4196  c-2.2928-2.1218-4.9805-3.8274-7.9172-4.9056C32.0723,4.0363,26.1097,3.995,20.7871,5.8372  C14.7889,7.8907,9.6815,12.3763,6.8497,18.0459c-0.9859,1.9536-1.7057,4.0388-2.1381,6.1836  C3.6238,29.5732,4.382,35.2707,6.8468,40.1378c1.6019,3.1768,3.8985,6.001,6.6843,8.215c2.6282,2.0958,5.6916,3.6439,8.9396,4.5078  c4.0984,1.0993,8.461,1.0743,12.5864,0.1355c3.7284-0.8581,7.256-2.6397,10.0725-5.24c2.977-2.7358,5.1006-6.3403,6.2249-10.2138  C52.5807,33.3171,52.7498,28.8064,51.981,24.4812z' />
                    </svg>
                  }
                  onClick={() => signUpWithGoogle()}
                >
                  Sign up with Google
                </Button>
              </Box>
              <Divider sx={{ fontSize: 13 }}>or continue with</Divider>
              {error && (
                <Box mt={4} mb={2}>
                  <Alert variant='outlined' severity='error'>
                    {error}
                  </Alert>
                </Box>
              )}
              <Stack direction='column' spacing={4} mt={4}>
                <FormControl
                  variant='standard'
                  sx={{ width: '100%' }}
                  error={!!error}
                >
                  <InputLabel shrink htmlFor='bootstrap-input'>
                    Email
                  </InputLabel>
                  <DcInput
                    id='email'
                    value={emailInput}
                    onChange={(evt) => setEmailInput(evt.target.value)}
                    placeholder='Enter your email id'
                    fullWidth
                  />
                </FormControl>
                <FormControl
                  variant='standard'
                  sx={{ width: '100%' }}
                  error={!!error}
                >
                  <InputLabel shrink htmlFor='bootstrap-input'>
                    Name
                  </InputLabel>
                  <DcInput
                    id='name'
                    value={nameInput}
                    onChange={(evt) => setNameInput(evt.target.value)}
                    placeholder='Enter your name'
                    fullWidth
                  />
                </FormControl>
                <FormControl
                  variant='standard'
                  sx={{ width: '100%' }}
                  error={!!error}
                >
                  <InputLabel shrink htmlFor='password'>
                    Password
                  </InputLabel>
                  <DcInput
                    id='password'
                    value={passwordInput}
                    onChange={(evt) => setPasswordInput(evt.target.value)}
                    placeholder='Enter your password'
                    type='password'
                    fullWidth
                  />
                </FormControl>
              </Stack>
              <Box mt={4} mb={2}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={checkSignUpDisabled()}
                  onClick={handleSubmit}
                >
                  SIGN UP
                </Button>
              </Box>
              <Grid
                container
                spacing={0}
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeTC}
                        onChange={(evt) => {
                          setAgreeTC(prev => !prev)
                        }}
                        icon={<CheckboxUncheckedIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    }
                    label={TnCLabel}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
